<script>
import draggable from 'vuedraggable'
import EditeurDevoirV2 from '@/mixins/editeurDevoirV2.js'
import FormatDate from '@/mixins/formatDate.js'
import eleveSelectionParent from '@/mixins/eleveSelectionParent.js'
import openLink from '@/mixins/openLink.js'

import SelectElevesV2 from '@/components/dialogs/actions/selectElevesV2'
import GroupListSelectionPreview from '@/components/groupes/GroupListSelectionPreview'

export default {
  name: 'devoirEdition',
  mixins: [EditeurDevoirV2, FormatDate, eleveSelectionParent, openLink],
  components: {
    draggable,
    SelectElevesV2,
    GroupListSelectionPreview
  },
  data () {
    return {
      creationLoading: false,
      validForm: true,
      titreRules: [v => !!v || this.$t('messages.titre-requis')],
      dateDebutRules: [v => !!v || this.$t('messages.date-requis')],
      dateFinRules: [v => !!v || this.$t('messages.date-requis-fin')],
      titre: null,
      description: null,
      menuDateDebut: false,
      menuDateFin: false,
      dateDebut: null,
      dateFin: null,
      maxScore: null,
      finish: false,
      backupData: null,
      showSelectEleves: false,
      initialNuageItem: null
    }
  },
  computed: {
    isModification () {
      return !!this.$route.query?.id
    },
    gotUnlimitedTag () {
      let temp = false
      if (this.selectedExercices.length > 0) {
        if (this.selectedExercices[0].children && this.selectedExercices[0].children.length > 0) {
          if (this.selectedExercices[0].children[0].parent && this.selectedExercices[0].children[0].parent.taggings && this.selectedExercices[0].children[0].parent.taggings.length > 0) {
            if (this.selectedExercices[0].children[0].parent.taggings.find(e => e.tag.id === 'config-boutique-sans_limite_credit')) {
              temp = true
            }
          }
        }
      }
      return temp
    },
    isDistribuable () {
      let temp = false
      if (this.selectedExercices.length > 0) {
        if (this.selectedExercices[0].distribution && this.selectedExercices[0].distribution.length > 0) {
          if (this.selectedExercices[0].distribution.find(d => d.role === 'salle_classe_eleve')) {
            return true
          }
        }
      }
      return temp
    },
    isBoutiqueItem () {
      let temp = false
      if (this.selectedExercices.length > 0) {
        if (this.selectedExercices[0] && (this.selectedExercices[0].type === 'BoutiqueItem' || this.selectedExercices[0].type === 'BoutiqueItemContenu')) {
          temp = true
        }
      }
      return temp
    },
    canAddEleve () {
      let temp = false
      if (this.selectedExercices.length > 0) {
        if (this.selectedExercices[0] && this.selectedExercices[0].type !== 'BoutiqueItem' && this.selectedExercices[0].type !== 'BoutiqueItemContenu') {
          temp = true
        }
      }
      if (this.gotUnlimitedTag || this.exerciceAvailable) {
        temp = true
      }
      // on peut ajouter des eleves si on a credits illimite ou si l'exo a été distribué
      return temp
    },
    gotCredit () {
      let temp = false
      if (this.selectedExercices.length > 0) {
        if (this.selectedExercices[0].distribution && this.selectedExercices[0].distribution.length > 0) {
          if (this.selectedExercices[0].distribution.find(d => d.role === 'salle_classe_eleve')) {
            if (this.selectedExercices[0].distribution.find(d => d.role === 'salle_classe_eleve').total > 0 || this.selectedExercices[0].distribution.find(d => d.role === 'salle_classe_eleve').total === -1) {
              temp = true
            }
          }
        }
      }
      return temp
    },
    areSelectedExercicesAlreadyDistributed () {
      return this.selectedExercices?.[0]?.nuageItemsCreateursTotal > 0
    },
    exerciceAvailable () {
      let temp = false
      if (this.selectedExercices.length > 0) {
        if (this.selectedExercices.filter(e => e.inClasse > 0).length > 0) {
          temp = true
        }
      }
      return temp
    },
    showExercicesSelection: {
      set (val) { this.$store.commit('Dialogs/setExercicesSelection', val) },
      get () { return this.$store.state.Dialogs.showExercicesSelection }
    },
    selectedExercices: {
      set (val) {
        this.$store.commit('Dialogs/setSelectedExercices', val)
      },
      get () { return this.$store.state.Dialogs.selectedExercices }
    },
    selectedExercicesDetails () {
      let exercicesDetails = []
      this.$store.state.Dialogs.selectedExercices.forEach((e) => {
        if (e?.children?.length) {
          exercicesDetails.push(...e.children)
        } else {
          exercicesDetails.push(e)
        }
      })
      return exercicesDetails
    },
    selectedNuageItems () {
      const nuageItemsSet = new Set()
      for (const selectedExercice of this.selectedExercicesDetails) {
        if (selectedExercice.nuage) {
          nuageItemsSet.add(selectedExercice.nuage)
        }
      }

      return this.selectedExercicesDetails?.length ? [...nuageItemsSet] : [this.initialNuageItem]
    },
    getUrlDokoma () {
      let temp = 'https://www.laclassealamaison.com'
      if (this.$store.state.User.profile) {
        temp += '?dokUANE=' + this.$store.state.User.profile.info.username + '&access=' + this.$store.state.User.profile.info.accessCode
      }
      return temp
    },
    pageTitle () {
      if (this.$route.query?.id) {
        return this.$t('action.modif-de') + ' ' + this.titre
      } else if (this.$route.query?.produit) {
        const preselectedProduitId = this.$route.query?.produit
        const preselectedProduit = this.$store.state.App.treeviewExercices?.find(exo => exo?.id === preselectedProduitId)
        const preselectedProduitName = preselectedProduit?.name
        return this.$t('exercices.nouveau-devoir') + (preselectedProduitName ? ` - ${preselectedProduitName}` : '')
      } else {
        return this.$t('exercices.nouveau-devoir')
      }
    }
  },
  async created () {
    const identifiant = this.$route.query?.id
    if (identifiant) {
      console.log('coucou', identifiant)
      // If we are in Edition more, pre-fill the form with data from the Devoir
      await this.$store.dispatch('App/fetchRessourcesExercices')

      this.initialiseSallesUsagersFromIdentifiant(identifiant)

      const { data } = await this.$apollo.query({
        query: require('@/graphql/queries/v2/eleveSelection/calendrierCalendrierElementsNuageItems.gql'),
        variables: { filtre: { identifiants: [identifiant] } }
      })
      const cce = data.calendrierCalendrierElements.resultat[0]

      this.titre = cce.titre
      this.description = cce.description
      this.dateDebut = cce.debutAt ? cce.debutAt.split('T')[0] : null
      this.dateFin = cce.finAt ? cce.finAt.split('T')[0] : null
      this.maxScore = cce.note
      this.initialNuageItem = cce?.contenu?.nuageItem?.id
    }
    if (this.$route.query && this.$route.query.produit) {
      await this.$store.dispatch('App/fetchRessourcesExercices')
    }
  },
  beforeDestroy () {
    this.$store.commit('Dialogs/resetSelectedExercices')
  },
  methods: {
    removeFromExerciceList (id) {
      let index = this.selectedExercices.findIndex(el => el.id === id)
      this.selectedExercices.splice(index, 1)
    },
    previewExercice (et) {
      const { username, accessCode } = this.$store.state.User.profile.info
      let url = 'https://www.laclassealamaison.com/lecon/'
      url += et.type === 'Collection' || et.type === 'Série' ? et.slug : et.parentslug + '/graph/' + et.idtab
      url += '?dokUANE=' + username + '&access=' + accessCode
      this.writeTokenInCookiesAndOpenLink(url)
    },
    validateThenCreateOrModifyDevoir () {
      const isSelectionValid = this.isModification ? true : this.selectedExercices?.length
      if (this.$refs.creationForm.validate() && isSelectionValid) {
        this.creationLoading = true
        if (this.isModification) {
          this.editDevoir()
        } else {
          this.createDevoir()
        }
      } else {
        console.error('Devoir Form invalid')
      }
    },
    validateUpdate () {
      if (this.$refs.creationForm.validate() && this.selectedExercices && this.selectedExercices.length) {
        this.creationLoading = true
        setTimeout(() => {
          this.creationLoading = false
        }, 3000)
      } else {
        console.error('erreur validate update')
      }
    },
    checkIfDraft () {
      const vars = [this.titre, this.description, this.dateDebut, this.dateFin, this.selectedExercices]
      return !vars.every(v => v === null || v?.length === 0)
    }
  },
  watch: {
    '$store.state.Dialogs.selectedExercices': {
      deep: true,
      handler (newVal) {
        if (newVal.length === 0) {
          this.selectedSallesUsager = []
        }
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    const isDraft = this.checkIfDraft()
    if (!this.finish && isDraft) {
      const answer = window.confirm(this.$t('messages.confirm-sorti'))
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  }
}
</script>

<template lang='pug'>
  .devoirs-creation-index.split-page
    v-fade-transition
      v-overlay(v-if='creationLoading', fixed, color='black', opacity='0.75', z-index='100')
        div.tc
          div.f {{isModification ? $t('exercices.modif-devoir') : $t('exercices.creation-en-cours') }}
          div.mt3 {{ $t('messages.patienter') }}
          v-progress-circular.mt4(size='70', width='5', color='primary', indeterminate)

    select-eleves-v2(
      v-if="showSelectEleves"
      :initialSelectedUsagers="selectedSallesUsager"
      :nuageItems="selectedNuageItems"
      @close="showSelectEleves = false"
      @select="onSelectSallesUsager"
      @display="onDisplaySallesUsager"
    )

    .page-header(color='primary')
      .header__inner-content
        .flex.flex-column(style="width: fit-content")
          v-btn.white--text.mb2(:small='$store.state.App.mobileTemplate', :to='{ name: "devoirs" }', rounded, color='primary')
            font-awesome-icon.mr2.f4(:icon="['fas', 'arrow-left']")
            span {{ $t('action.retour-devoir') }}
          v-btn.white--text.mb2(:small='$store.state.App.mobileTemplate', :to='{ name: "exercices" }', rounded, color='primary')
            font-awesome-icon.mr2.f4(:icon="['fas', 'arrow-left']")
            span {{ $t('action.retour-exercices') }}
        div.flex-grow-1
        .header-title()
          div.main-title() {{ pageTitle }}

    .page__main-content
      //- Bloc description
      section.small-section
        .section-subtitle__header
          h2.section-subtitle {{ $t('messages.info-gene') }}
        v-sheet.section-card(id='d-step-1', light, :color='$store.state.Preferences.darkMode ? "light" : "white"')
          v-form(ref='creationForm', id='formCreation', v-model='validForm', lazy-validation, light)
            v-text-field(v-model='titre', light, :rules='titreRules' outlined, :placeholder='$t("exercices.exercice-sans-titre")', :label='$t("exercices.titre-placeholder")')
            v-textarea(v-model='description', outlined, :placeholder='$t("partage.description")', :label='$t("exercices.description-devoir")')
            v-menu(ref='menuDebut', v-model='menuDateDebut', :rules='dateDebutRules', :close-on-content-click='false', :return-value.sync='dateDebut', transition='scale-transition', offset-y, min-width='290px')
              template(v-slot:activator='{ on, attrs }')
                v-text-field(dense, v-model='dateDebutFormatted', outlined, :placeholder='$t("partage.facultatif")', :label='$t("calendrier.date-debut")', prepend-icon='mdi-calendar', clearable,readonly, v-bind='attrs', v-on='on')
              v-date-picker(v-model='dateDebut', no-title, scrollable, color='primary', :max='dateFin', @change='$refs.menuDebut.save(dateDebut)')
            v-menu(ref='menuFin', v-model='menuDateFin', :close-on-content-click='false', :return-value.sync='dateFin', transition='scale-transition', offset-y, min-width='290px')
              template(v-slot:activator='{ on, attrs }')
                v-text-field(dense, v-model='dateFinFormatted', :rules='dateFinRules', required, outlined, :placeholder='$t("partage.facultatif")', :label='$t("calendrier.date-fin")', prepend-icon='mdi-calendar', clearable, readonly, v-bind='attrs', v-on='on')
              v-date-picker(v-model='dateFin', no-title, scrollable, color='primary', :min='dateDebut', @change='$refs.menuFin.save(dateFin)')
            v-text-field(dense, v-model='maxScore', outlined, placeholder='10', :label='$t("exercices.pointage-devoir")')

      //- Bloc contenu
      section.large-section.devoir-content
        template(v-if="!isModification")
          .section-subtitle__header
            h2.section-subtitle {{ $t('exercices.devoir-content') }}
            v-btn(small, outlined, rounded, @click='showExercicesSelection = !showExercicesSelection') {{ $t('exercices.ajout-exo') }}
          v-sheet.section-card.mb4(id='d-step-2', :class='{"warning-wrapper" : !validForm && (!selectedExercices || selectedExercices.length < 1) }', light, :color='$store.state.Preferences.darkMode ? "light" : "white"')
            template(v-if='selectedExercices && selectedExercices.length > 0')
              v-list(key='list', style='background-color:transparent;')
                draggable(
                  id="list-devoir"
                  :list="selectedExercices"
                  class="list-group"
                  draggable=".item"
                  group="a",
                  ghost-class='ghost'
                  animation='150'
                  handle='.handle'
                )
                  transition-group(type="transition")
                    v-list-item.list-group-item.item.exercice-item(:key='"base-" + index', text, v-for='(exo, index) in selectedExercices', color='secondary')
                      v-list-item-avatar.white--text.f4(color='primary') {{index + 1}}
                      v-list-item-content
                        v-list-item-subtitle(v-if='exo.parent') {{exo.parent.titre ? exo.parent.titre : exo.parent}}
                        v-list-item-title(v-html="exo && exo.sousTitre ? exo.sousTitre : exo.name")
                      //- v-list-item-action
                      //-   v-btn(@click='previewExercice(exo)', icon, small, v-tooltip.bottom="{ content: 'Voir le contenu', offset:'10px'}")
                      //-     font-awesome-icon.f5.secondary--text(:icon="['fad', 'eye']")
                      v-list-item-action
                        v-btn(@click='removeFromExerciceList(exo.id)', icon, small, v-tooltip.bottom="{ content: 'Retirer l´exercice', offset:'10px'}")
                          font-awesome-icon.f5.secondary--text(:icon="['fad', 'trash-alt']")
                      div.handle.ml2()
                        font-awesome-icon.f5.secondary--text(:icon="['fas', 'grip-lines']")
            template(v-else)
              div.empty-content__wrapper
                div {{ $t('exercices.devoir-vide') }}
                v-btn.mt3(rounded, color='primary', @click='showExercicesSelection = !showExercicesSelection') {{ $t('exercices.ajout-exo') }}
                v-btn.mt3(rounded, color='primary', outlined, href='https://www.laclassealamaison.com', target='_blank')
                  img.mr2(src='@/assets/images/logos/logo-classe-maison.svg', height='20px')
                  span {{ $t('action.explore-classe') }}

        //- Bloc eleves
        .section-subtitle__header
          h2.section-subtitle(v-if='selectedSallesUsager && selectedSallesUsager.length') {{ $t('eleves.eleves-maj') }} {{selectedSallesUsager.length ? '(' + selectedSallesUsager.length + ')' : ''}}
          v-btn(v-if="isModification" small outlined rounded @click='showSelectEleves = !showSelectEleves') {{ $t('eleves.modif-liste-eleves') }}
          v-btn(v-else small outlined rounded @click='showSelectEleves = !showSelectEleves' :disabled='!canAddEleve') {{ $t('action.ajout-eleves') }}
        v-sheet.section-card(id='d-step-3', light, :color='$store.state.Preferences.darkMode ? "light" : "white"')
          template(v-if='selectedSallesUsager && selectedSallesUsager.length')
            group-list-selection-preview(:groupList="displayedSallesUsagers")
          template(v-else)
            div.empty-content__wrapper
              v-alert.mt3(type='error', v-if='selectedExercices && isDistribuable && !exerciceAvailable && !gotUnlimitedTag') {{ $t('lecture.besoin-attribut') }}
              v-alert.mt3(type='error', v-else-if='selectedExercices && !gotCredit && !gotUnlimitedTag && isBoutiqueItem && !areSelectedExercicesAlreadyDistributed') {{ $t('messages.buy-licence')}}
              div(v-else) {{$t('lecture.votre')}}
                span.b &nbsp;{{ $t('menu.devoir') }}
                span &nbsp; {{ $t('lecture.sans-personne') }}
              v-btn.mt3(color='primary', @click='showSelectEleves = !showSelectEleves', :disabled='!canAddEleve', rounded) {{ $t('action.ajout-eleves') }}
        .save-button.pv4
          v-btn(@click='validateThenCreateOrModifyDevoir()', x-large, color='primary', rounded, block) {{ $route.query && $route.query.id ? $t('exercices.modifier-devoir') : $t('exercices.creer-devoir') }}
          v-alert.mt3(type='error', border='left', :value='!validForm', dense, transition="scale-transition") {{ $t('messages.validez-champs') }}.
</template>
<style lang='sass'>
@import 'src/styles/pages/_devoirsCreation'

</style>
