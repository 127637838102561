export default {
  methods: {
    async createDevoir () {
      /* How to create a devoir ?
      Devoir = collection of Exercices (CCE), distributed a list of students (ClasseUsagers)

      0.before Generate GraphArbre if the exercice is made out of teacher's own questions
      0. Generate a list of "attrs", wich are the attrs that would be used in V1, sent in individual requests
      1. Send the list of attrs with "devoirCalendrierElement", wich will create every CalendrierCalendrierElement in the User personnal calendar (Profile.CalendrierDevoir)
      2. The "devoirCalendrierElement" send back one of the CCE created, we just need to keep the "identifiant"
      3. Using the identifiant, distribute the devoir to all the students with distribuerUsagerCalendrierElement
      4. If everything went well, the Devoir is created
      */
      this.finish = false

      try {
        // 0.before
        const graphId = await this.generateGraphArbreFromQuestions()
        console.log({ graphId })
        // 0
        const attrsList = this.generateAttrsListFromSelectedExercices(graphId)
        console.log({ attrsList })
        // 1, 2
        const identifiant = await this.createCceFromAttrsList(attrsList)
        console.log({ identifiant })
        // 3
        await this.distribuerDevoirToSalleUsagers(identifiant)
        // 4
        this.onFinished(identifiant)
      } catch (e) {
        console.error(e)
        this.onFinished()
      }
    },
    async editDevoir () {
      /* How to edit a devoir ?
      "meta data" = titre, description, dates debut/fin, noteMax

      0. Update the Meta Data of devoir
      1. Update the Students list
      2. If everything went well, the Devoir is updated
      */
      this.finish = false
      const identifiant = this.$route.query?.id
      try {
        // 0
        await this.updateDevoirMeta(identifiant)

        // 1
        await this.distribuerDevoirToSalleUsagers(identifiant)

        // 2
        this.onFinished(identifiant)
      } catch (e) {
        console.error(e)
        this.onFinished(identifiant)
      }
    },
    async generateGraphArbreFromQuestions () {
      if (this.selectedExercices?.find(se => se.type === 'ExercicesQuestion')) {
        const { data } = await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/v2/arbreExercicesQuestions.gql'),
          variables: {
            attrs: { titre: this.titre, description: this.description },
            questions: this.selectedExercices?.map(se => se.id)
          }
        })
        if (data?.arbreExercicesQuestions?.id && !data?.arbreExercicesQuestions?.errors) {
          return data?.arbreExercicesQuestions?.id
        }
        return null
      }
      return null
    },
    generateAttrsListFromSelectedExercices (graphId) {
      // If exercice is made from my own questions
      if (graphId) {
        return [{
          statut: 'ACTIF',
          titre: this.titre ?? 'Devoir du ' + this.$dayjs().format('DD MMM YYYY'),
          description: this.description,
          debutAt: this.dateDebut ? new Date(this.dateDebut + 'T00:00:00').toJSON() : null,
          finAt: this.dateFin ? new Date(this.dateFin + 'T00:00:00').toJSON() : null,
          note: Number.isFinite(this.maxScore) ? parseInt(this.maxScore) : 100,
          contenu: { type: 'ARBRE', id: graphId }
        }]
      }

      // If exercices are from LaClasseALaMaison or V2
      const attrsList = this.selectedExercices.map(exo => {
        // Create global attrs
        const attrs = {
          statut: 'ACTIF',
          titre: this.titre ?? 'Devoir du ' + this.$dayjs().format('DD MMM YYYY'),
          description: this.description,
          debutAt: this.dateDebut ? new Date(this.dateDebut + 'T00:00:00').toJSON() : null,
          finAt: this.dateFin ? new Date(this.dateFin + 'T00:00:00').toJSON() : null,
          note: Number.isFinite(this.maxScore) ? parseInt(this.maxScore) : 100
        }

        // Personnalise meta and contenu
        let meta = null
        let contenu = null

        if (exo.type === 'Série') {
          meta = { version: 1, data: { type: 'serie', id: 'gid://v1/Graphs::Graph::Super/' + exo.id } }
        } else if (exo.type === 'SuperNoeud') {
          meta = { version: 1, data: { type: 'exercice', id: 'gid://v1/Graphs::Graph::SuperNoeud/' + exo.id } }
        } else {
          const slug = exo.parentslug ? exo.parentslug : exo.slug
          contenu = { id: slug, type: 'ITEM' }
          // Maybe one day, we could refactor this meta to use an object instead of this annoying "id" string
          meta = {
            version: 1,
            data: {
              type: 'boutiqueItem',
              id: 'gid://v2/Nuage/' + exo.nuage + '/BoutiqueItem/' + slug,
              contenu: exo.parent ? [exo.id] : exo.children.map(c => c.id)
            }
          }
        }

        if (contenu) { attrs.contenu = contenu }
        if (meta) { attrs.meta = JSON.stringify(meta) }

        return attrs
      })
      return attrsList
    },
    async createCceFromAttrsList (attrsList) {
      const { data } = await this.$apollo.mutate({
        mutation: require('@/graphql/mutations/v2/devoirCalendrierElement.gql'),
        variables: { attrs: attrsList }
      })
      if (!data.devoirCalendrierElement.identifiant || data.devoirCalendrierElement?.errors) {
        throw new Error(data.devoirCalendrierElement.errors ?? 'Failed to create devoir with devoirCalendrierElement')
      }
      return data.devoirCalendrierElement.identifiant
    },
    async distribuerDevoirToSalleUsagers (id) {
      const sallesUsagers = this.selectedSallesUsager.map(ssu => ssu.id)
      const { data } = await this.$apollo.mutate({
        mutation: require('@/graphql/mutations/v2/distribuerUsagerCalendrierElement.gql'),
        variables: { id, sallesUsagers }
      })
      if (!data.distribuerUsagerCalendrierElement) {
        throw new Error('Failed to distribute Cce to SallesUsagers')
      }
      return true
    },
    onFinished (identifiant = null) {
      this.creationLoading = false
      if (identifiant) {
        this.$store.dispatch('Exercices/fetchDevoirsRecent')
        this.finish = true
        this.$router.push({
          name: 'devoirDetail',
          params: { id: identifiant },
          query: { identifiant: true }
        })
      } else {
        this.$store.commit('App/setSnackbarGlobalContent', { type: 'error', titre: this.$t('alerte.erreur'), description: this.$t('alerte.create-devoir-error') })
        this.$store.commit('App/setShowSnackbarGlobal', true)
      }
    },
    async updateDevoirMeta (identifiant) {
      const { data } = await this.$apollo.mutate({
        mutation: require('@/graphql/mutations/v2/updateCalendrierElement.gql'),
        variables: {
          id: identifiant,
          attrs: {
            titre: this.titre,
            description: this.description,
            debutAt: this.dateDebut ? new Date(this.dateDebut + 'T00:00:00').toJSON() : null,
            finAt: this.dateFin ? new Date(this.dateFin + 'T00:00:00').toJSON() : null,
            note: Number.isFinite(this.maxScore) ? parseInt(this.maxScore) : 100
          }
        }
      })
      if (!data.updateCalendrierElement || data.updateCalendrierElement.errors) {
        throw new Error('Failed to update Devoir Meta.', data.updateCalendrierElement.errors)
      }
    }
  }
}
